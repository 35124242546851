.layout {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}

.layout > .header {
    flex: 1 0 auto;
    min-width: 1680px;
}

html, body {
    margin: 0;
    height: 100%;
    min-width: 1680px;
}

#root {
    height: 100%;
}

.fillHeight {
    height: 100%;
}

.fillWidth {
    width: 100%;
}

.setHeightSideBar { /* header is 64px */
    height: -moz-calc(100% - 64px); /* Firefox */
    height: -webkit-calc(100% - 64px); /* Chrome, Safari */
    height: calc(100% - 64px); /* IE9+ and future browsers */
}

.mainContentLayout {
    margin: 20px 16px 16px;
}

.searchField {
    min-width: 300px;
}

.mainContentLayout .eds-table-heading {
    min-width: 100px;
}
